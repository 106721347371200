import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import Footer from "../components/footer"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Scroller from "../components/scroller"
import PortfolioModal from "../components/portfolio/modal"
import PortfolioCarousel from "../components/portfolio/carousel"

import { StaticImage } from "gatsby-plugin-image"

export default class IndexPage extends React.Component {
  constructor(props) {
    super(props)
    Scroller.handleAnchorScroll = Scroller.handleAnchorScroll.bind(this)
    this.state = {
      modalShow: false,
      modalCurrent: 0,
    }
    this.handlePortfolioClick = this.handlePortfolioClick.bind(this)
    this.setModal = this.setModal.bind(this)
  }

  handlePortfolioClick(index, e) {
    e.preventDefault()
    this.setModal(true, index)
  }

  setModal(isShown, current) {
    this.setState({
      modalShow: isShown,
      modalCurrent: current,
    })
  }

  render() {
    return (
      <Layout>
        <SEO title="Home" />

        <section id="portfolio">
          <div className="container-fluid p-0">
            <div className="row no-gutters">
              <div className="col-lg-4 col-sm-6">
                <a
                  className="portfolio-box"
                  href="https://www.onpointland.com/"
                  target="_blank"
                  // href="img/portfolio/fullsize/1.jpg"
                  // onClick={this.handlePortfolioClick.bind(this, 0)}
                  rel="noreferrer"
                >
                  <Img
                    fluid={
                      this.props.data.images.edges[0].node.childImageSharp.fluid
                    }
                  />
                  <div className="portfolio-box-caption">
                    <div className="project-category text-white-50">
                      Chief Legal Counsel
                    </div>
                    <div className="project-name">
                      On Point Land Services Corporation
                    </div>
                  </div>
                </a>
              </div>
              <div className="col-lg-4 col-sm-6">
                <a
                  className="portfolio-box"
                  href="https://www.keensettlement.com/"
                  target="_blank"
                  // href="img/portfolio/fullsize/2.jpg"
                  // onClick={this.handlePortfolioClick.bind(this, 1)}
                  rel="noreferrer"
                >
                  <Img
                    fluid={
                      this.props.data.images.edges[1].node.childImageSharp.fluid
                    }
                  />
                  <div className="portfolio-box-caption">
                    <div className="project-category text-white-50">
                      General Counsel
                    </div>
                    <div className="project-name">
                      Keen Settlement Services, Inc.
                    </div>
                  </div>
                </a>
              </div>
              <div className="col-lg-4 col-sm-6">
                <a
                  className="portfolio-box"
                  href="https://www.turturrolawpc.com/"
                  target="_blank"
                  // href="img/portfolio/fullsize/3.jpg"
                  // onClick={this.handlePortfolioClick.bind(this, 2)}
                  rel="noreferrer"
                >
                  <Img
                    fluid={
                      this.props.data.images.edges[2].node.childImageSharp.fluid
                    }
                  />
                  <div className="portfolio-box-caption">
                    <div className="project-category text-white-50">
                      Of Counsel
                    </div>
                    <div className="project-name">Turturro Law, P.C.</div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </section>

        <PortfolioModal
          show={this.state.modalShow}
          onHide={() => this.setModal(false, 0)}
        >
          <PortfolioCarousel
            images={this.props.data.images.edges}
            current={this.state.modalCurrent}
          />
        </PortfolioModal>

        <section className="page-section bg-secondary text-white" id="reviews">
          <div className="container text-center mb-5">
            <div className="mb-4">
              <a
                className="btn"
                href="https://www.avvo.com/attorneys/11566-ny-anthony-nozzolillo-993256/reviews.html"
              >
                <StaticImage
                  src="../images/avvo-clients-choice-2023.png"
                  alt="Anthony A. Nozzolillo, Esq. logo"
                  placeholder="blurred"
                  width={324}
                  height={233}
                />
              </a>
            </div>
            <h2 className="mb-4">100% Five Star Only Reviews on Avvo: 50</h2>
            <div className="mt-0">
              <i className="fas fa-2x fa-star text-warning mb-4"></i>
              <i className="fas fa-2x fa-star text-warning mb-4"></i>
              <i className="fas fa-2x fa-star text-warning mb-4"></i>
              <i className="fas fa-2x fa-star text-warning mb-4"></i>
              <i className="fas fa-2x fa-star text-warning mb-4"></i>
            </div>
            <div>
              <a
                className="btn btn-light btn-xl"
                href="https://www.avvo.com/attorneys/11566-ny-anthony-nozzolillo-993256/reviews.html"
                target="_blank"
              >
                Read the Reviews
              </a>
            </div>
            <br />
          </div>
          <div className="row no-gutters items-align-center text-center p-5 bg-white">
            <div className="col-lg-3 col-sm-6 p-2">
              <StaticImage
                src="../images/mh-peer-reviewed.png"
                alt="Martindale-Hubbell Peer Reviewed"
                placeholder="blurred"
                width={200}
                height={38}
              />
            </div>
            <div className="col-lg-3 col-sm-6 p-2">
              <StaticImage
                src="../images/Anthony_A_Nozzolillo_CR_Platinum_250.png"
                alt="Martindale-Hubbell Client Champion Platinum 2024"
                placeholder="blurred"
                width={200}
                height={44}
              />
            </div>
            <div className="col-lg-3 col-sm-6 p-2">
              <StaticImage
                src="../images/anthony-nozzolillo-esq-justia-10.0-placeholder-badge.png"
                alt="Justia 10.0 Placeholder Badge"
                placeholder="blurred"
                width={59}
                height={59}
              />
            </div>
            <div className="col-lg-3 col-sm-6 p-2">
              <StaticImage
                src="../images/lawyer.com-premium_200.png"
                alt="Lawyer.com Premium"
                placeholder="blurred"
                width={200}
                height={59}
              />
            </div>
          </div>
          <div className="row no-gutters items-align-center text-center p-5">
            <div className="col-lg-12 text-center">
              <h2 className="text-white mt-0 text-center">
                Honors &bull; Recognition &bull; Awards
              </h2>
              <hr className="divider light my-4" />
            </div>
            <div className="col-lg-4 col-sm-6 p-2">
              <StaticImage
                src="../images/NYAMB-congratulations-2023.png"
                alt="NYAMB Congratulations 2023"
                placeholder="blurred"
                width={500}
                height={500}
              />
            </div>
            <div className="col-lg-4 col-sm-6 p-2">
              <StaticImage
                src="../images/NYAMB-plaque-2023.png"
                alt="NYAMB Plaque 2023"
                placeholder="blurred"
                width={500}
                height={500}
              />
            </div>
            <div className="col-lg-4 col-sm-6 p-2">
              <StaticImage
                src="../images/NYAMB-receiving-award-2023.png"
                alt="NYAMB Receiving Award 2023"
                placeholder="blurred"
                width={500}
                height={500}
              />
            </div>
          </div>
          <div className="row no-gutters items-align-center text-center p-5">
            <div className="col-lg-4 col-sm-6 p-2">
              <StaticImage
                src="../images/NYAMB-affiliate-member-of-the-year-2023.png"
                alt="Anthony A. Nozzolillo, Esq. NYAMB 2023 News"
                placeholder="blurred"
                width={565}
                height={480}
              />
            </div>
            <div className="col-lg-4 col-sm-6 p-2">
              <StaticImage
                src="../images/bruce-blakeman.png"
                alt="ESMBA Networking EventBruce Blakeman"
                placeholder="blurred"
                width={360}
                height={480}
              />
            </div>
            <div className="col-lg-4 col-sm-6 p-2">
              <StaticImage
                src="../images/anthony-bruce.jpg"
                alt="Anthony A. Nozzolillo, Esq. with Bruce Blakeman"
                placeholder="blurred"
                width={360}
                height={480}
              />
            </div>
          </div>
          <div className="row no-gutters items-align-center text-center p-5">
            <div className="col-lg-4 col-sm-6 p-2">
              <StaticImage
                src="../images/anthony-nozzolillo-heritage-club.jpg"
                alt="Anthony A. Nozzolillo, Esq. - Real Estate Achievers & Leadership Awards 2024"
                placeholder="blurred"
                width={500}
                height={500}
              />
            </div>
            <div className="col-lg-4 col-sm-6 p-2">
              <StaticImage
                src="../images/anthony-nozzolillo-esq-REAL-Awards-2024-acceptance.png"
                alt="Anthony A. Nozzolillo, Esq. - Real Estate Achievers & Leadership Awards 2024 - Acceptance"
                placeholder="blurred"
                width={600}
                height={500}
              />
            </div>
            <div className="col-lg-4 col-sm-6 p-2">
              <StaticImage
                src="../images/anthony-nozzolillo-esq-REAL-Awards-2024-with-award.png"
                alt="Anthony A. Nozzolillo, Esq. - Real Estate Achievers & Leadership Awards 2024"
                placeholder="blurred"
                width={330}
                height={500}
              />
            </div>
          </div>
          <div className="row no-gutters items-align-center text-center p-5">
            <div className="col-lg-4 col-sm-6 p-2">
              <StaticImage
                src="../images/anthony-nozzolillo-esq-REAL-Awards-2024-actual-award.png"
                alt="Anthony A. Nozzolillo, Esq. - Real Estate Achievers & Leadership Awards 2024 - Award"
                placeholder="blurred"
                width={400}
                height={500}
              />
            </div>
            <div className="col-lg-4 col-sm-6 p-2">
              <StaticImage
                src="../images/anthony-nozzolillo-esq-Suffolk-County-Certificate-of-Achievement.png"
                alt="Anthony A. Nozzolillo, Esq. - Real Estate Achievers & Leadership Awards 2024"
                placeholder="blurred"
                width={600}
                height={500}
              />
            </div>
            <div className="col-lg-4 col-sm-6 p-2">
              <StaticImage
                src="../images/anthony-nozzolillo-esq-herald-long-island-choice-awards-best-real-estate-law-attorney.png"
                alt="Anthony A. Nozzolillo, Esq. - Herald Long Island Choice Awards - Best Real Estate Law Attorney 2024"
                placeholder="blurred"
                width={450}
                height={500}
              />
            </div>
          </div>
        </section>

        <section className="page-section bg-secondary p-0" id="about">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-12 text-center">
                <h2 className="text-white mt-0 text-center">
                  Trusted Attorney to Select Clientele
                </h2>
                <hr className="divider light my-4" />
                <p className="text-white mb-4">
                  Anthony A. Nozzolillo, Esq. is seasoned attorney representing
                  individual and corporate buyers, sellers, and lenders in both
                  routine and complex residential and commercial real estate
                  closing transactions; commercial and residential leasing
                  agreements (lessor and lessee); mortgage refinancing-
                  representing national and local lenders in closings on both
                  first and second residential and commercial loans.
                </p>
                <p className="text-white mb-4">
                  He serves as Chief Legal Counsel to a Real Estate Title
                  Insurance Company, On Point Land Services Corporation, and is
                  General Counsel to Keen Settlement Services, Inc. a Loan
                  Settlement Company, both located in Merrick, Long Island, New
                  York. He also serves as Of Counsel to the Real Estate and
                  Litigation Departments of Turturro Law, P.C., a full-service
                  Law Firm in Staten Island, New York.
                </p>
                <p className="text-white mb-4">
                  He is well versed in all aspects of Landlord/Tenant matters,
                  as well as Title and Title Insurance, and possesses vast
                  knowledge of Real Estate Litigation and dispute resolutions
                  (Evictions, Foreclosures, Breach of Contract Disputes, Escrow
                  Deposit Disputes, Title Disputes (Quiet Title/Notice of
                  Pendency), Boundary Line Disputes, Adverse Possession, Breach
                  of Warranty of Habitability). He is a frequent guest speaker
                  at Realtor and Mortgage Broker conventions involving first
                  time homebuyers and all facets of title insurance.
                </p>
                <p className="text-white mb-4">
                  Additionally, Mr. Nozzolillo serves as President / Legal to
                  the Board of Managers of The Meridian Condominium, a 36 Unit
                  Luxury Oceanfront Condominium located in Long Beach, New York.
                  He is also Community Counsel to 70-80 Gibbons Owners Corp., a
                  500 Unit Cooperative Building located in Valley Stream, New
                  York, and Building Attorney/Counsel for The Sandcastles
                  Condominium, a 2 building boutique Condominum located at the
                  West End of Long Beach, New York's famous Boardwalk.
                </p>
                <p className="text-white mb-4">
                  Although the current mainstay of his practice concentration is
                  Real Estate Law, having run his own Private Law Practice for
                  almost a decade, Mr. Nozzolillo provides additional legal
                  services not limited to: Drafting of Wills and Trusts;
                  Business Law; (Corporate Entity Formation, Contract Drafting);
                  Civil Litigation Representation; (Personal Injury; Slip and
                  Falls; Arbitration; Mediation); Small Claims Court Matters;
                  Contract Law (Partnership Agreements, Independent Contractor
                  Agreements, Prenuptial Agreements, etc.).
                </p>
                <p className="text-white mb-4">
                  He is the recipient of awards from The New York Association of
                  Mortgage Brokers (NYAMB), Martindale- Hubbell, The American
                  Registry, and the HERALD Community Newspapers. He has
                  represented celebrity clients, the late Alan Colmes (Hannity &
                  Colmes), as well as High Net Worth Investors.
                </p>
                <p className="text-white mb-4">
                  Mr. Nozzolillo received a Bachelor of Science in Accounting
                  from State University of New York at Albany (School of
                  Business) and a Juris Doctorate from Hofstra University School
                  of Law. He is an active member of the New York State Bar
                  Association; Nassau County Bar Association; American Bar
                  Association; American Association of Notaries; Affiliate
                  Member of Long Island Board of Realtors (LIBOR) and New York
                  Association of Mortgage Brokers (NYAMB). In addition, he is a
                  licensed New York State Real Estate Broker.
                </p>
                <br />
                <a
                  className="btn btn-light btn-xl js-scroll-trigger"
                  href="#services"
                  onClick={Scroller.handleAnchorScroll}
                >
                  Become a Connection
                </a>
              </div>
            </div>
          </div>
        </section>

        <section className="page-section bg-secondary text-white" id="videos">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-12 text-center">
                <h2 className="text-black mt-0">
                  Podcasts &bull; Appearances &bull; News
                </h2>
                <hr className="divider light my-4" />
                <div>
                  <iframe
                    class="responsive-iframe mx-2 my-2"
                    src="https://www.youtube.com/embed/hX-7SMvWASs"
                    title="YouTube video player"
                    frameborder="1"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowfullscreen
                  ></iframe>
                  <iframe
                    class="responsive-iframe mx-2 my-2"
                    src="https://www.youtube.com/embed/w3rT1nZrQEs"
                    title="YouTube video player"
                    frameborder="1"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowfullscreen
                  ></iframe>
                  <iframe
                    class="responsive-iframe mx-2 my-2"
                    src="https://www.youtube.com/embed/FMXlCDNPV4k"
                    title="YouTube video player"
                    frameborder="1"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowfullscreen
                  ></iframe>
                  <iframe
                    class="responsive-iframe mx-2 my-2"
                    src="https://www.youtube.com/embed/keMi-wWVQ8I"
                    title="YouTube video player"
                    frameborder="1"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowfullscreen
                  ></iframe>
                  <iframe
                    class="responsive-iframe mx-2 my-2"
                    src="https://www.youtube.com/embed/PedLr7tmsno"
                    title="YouTube video player"
                    frameborder="1"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowfullscreen
                  ></iframe>
                  <iframe
                    class="responsive-iframe mx-2 my-2"
                    src="https://www.youtube.com/embed/TAeM5i0c288"
                    title="YouTube video player"
                    frameborder="1"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowfullscreen
                  ></iframe>
                  <iframe
                    class="responsive-iframe mx-2 my-2"
                    src="https://www.youtube.com/embed/BcW2WV30Yl8?si=iMyX1yzoemxxXBDK"
                    title="YouTube video player"
                    frameborder="1"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowfullscreen
                  ></iframe>
                </div>

                <div className="row justify-content-center mt-5">
                  <div className="card card-width m-2">
                    <div className="card-body text-dark">
                      <StaticImage
                        className="card-img-top"
                        src="../images/anthony-nozzolillo-esq-li-herald-meet.jpg"
                        alt="Anthony A. Nozzolillo, Esq. - LI Herald"
                        placeholder="blurred"
                        height="240"
                      />
                      <h5 className="card-title">
                        Meet Anthony A. Nozzolillo, Esq., Long Island Real
                        Estate Attorney whose expertise goes well beyond
                        closings
                      </h5>
                      <p className="card-text">Long Island Herald</p>
                      <a
                        href="https://www.liherald.com/stories/meet-anthony-a-nozzolillo-esq-long-island-real-estate-attorney-whose-expertise-goes-well-beyond,205334"
                        className="btn btn-secondary"
                      >
                        Read Article
                      </a>
                    </div>
                  </div>
                  <div className="card card-width m-2">
                    <div className="card-body text-dark">
                      <StaticImage
                        className="card-img-top"
                        src="../images/anthony-nozzolillo-esq-liherald-italian-american.jpg"
                        alt="Anthony A. Nozzolillo, Esq. - LI Herald"
                        placeholder="blurred"
                        height="240"
                      />
                      <h5 className="card-title">
                        Long Island Attorney Anthony A. Nozzolillo relies on
                        Italian Heritage to ‘Guide’ Legal Practice
                      </h5>
                      <p className="card-text">Long Island Herald</p>
                      <a
                        href="https://www.liherald.com/stories/long-island-attorney-anthony-a-nozzolillo-relies-on-italian-heritage-to-guide-legal-practice,205998"
                        className="btn btn-secondary"
                      >
                        Read Article
                      </a>
                    </div>
                  </div>
                  <div className="card card-width m-2">
                    <div className="card-body text-dark">
                      <StaticImage
                        className="card-img-top"
                        src="../images/anthony-nozzolillo-esq-liherald-real-meaning-contract-terms.jpg"
                        alt="Anthony A. Nozzolillo, Esq. - LI Herald"
                        placeholder="blurred"
                        height="240"
                      />
                      <h5 className="card-title">
                        Breaking down the real meaning of these 4 contract terms
                        with Attorney Anthony A. Nozzolillo, Esq.
                      </h5>
                      <p className="card-text">Long Island Herald</p>
                      <a
                        href="https://www.liherald.com/bellmore/stories/contract-term-meanings-attorney-anthony-a-nozzolillo,206593"
                        className="btn btn-secondary"
                      >
                        Read Article
                      </a>
                    </div>
                  </div>
                  <div className="card card-width m-2">
                    <div className="card-body text-dark">
                      <StaticImage
                        className="card-img-top"
                        src="../images/anthony-nozzolillo-attorney-house-for-rent.png"
                        alt="Anthony A. Nozzolillo, Esq. - LI Herald"
                        placeholder="blurred"
                        height="240"
                      />
                      <h5 className="card-title">
                        Attorney takes on the Tumultuous Residential
                        Landlord/Tenant Relationship in New York State
                      </h5>
                      <p className="card-text">Long Island Herald</p>
                      <a
                        href="https://www.liherald.com/stories/landlord-tenant-rights-attorney-anthony-nozzolillo,207381"
                        className="btn btn-secondary"
                      >
                        Read Article
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="page-section" id="services">
          <div className="container">
            <h2 className="text-center mt-0">
              Connect &bull; Subscribe &bull; Follow
            </h2>
            <hr className="divider my-4" />
            <p className="text-center mt-2">
              Mr. Nozzolillo has authored many legal articles and has been
              published multiple times.
              <br />
              Connect on LinkedIn, subscribe to the YouTube channel, and click
              the Medium link below to read the articles.
            </p>

            <div className="row justify-content-center">
              <div className="col-lg-3 col-md-6 text-center">
                <div className="mt-5">
                  <a
                    className="d-block"
                    href="https://www.linkedin.com/in/anthonyanozzolillo/"
                  >
                    <i className="fab fa-4x fa-linkedin text-primary mb-4"></i>
                    <h3 className="h4 mb-2">LinkedIn</h3>
                  </a>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 text-center">
                <div className="mt-5">
                  <a
                    className="d-block"
                    href="https://www.youtube.com/@nozzolillolaw"
                  >
                    <i className="fab fa-4x fa-youtube text-primary mb-4"></i>
                    <h3 className="h4 mb-2">YouTube</h3>
                  </a>
                </div>
              </div>
              {/* <div className="col-lg-3 col-md-6 text-center">
                <div className="mt-5">
                  <a
                    className="d-block"
                    href="https://www.facebook.com/onpointlandservices/"
                  >
                    <i className="fab fa-4x fa-facebook text-primary mb-4"></i>
                    <h3 className="h4 mb-2">Facebook</h3>
                  </a>
                </div>
              </div> */}
              <div className="col-lg-3 col-md-6 text-center">
                <div className="mt-5">
                  <a className="d-block" href="https://medium.com/@nozzolillo/">
                    <i className="fab fa-4x fa-medium text-primary mb-4"></i>
                    <h3 className="h4 mb-2">Medium</h3>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="page-section bg-secondary text-white" id="contact">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-8 text-center">
                <h2 className="mt-0">Schedule a Free Consultation</h2>
                <hr className="divider my-4" />
                <p className="text mb-5">
                  Give us a call, or send us an email, and we will get back to
                  you promptly.
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-4 ml-auto text-center mb-5 mb-lg-0">
                <i className="fas fa-phone-volume fa-3x mb-3 text-muted"></i>
                <div>Office: (516) 600-9750</div>
                <div>Fax: (516) 600-9751</div>
                <div>Mobile: (516) 581-4713</div>
              </div>
              <div className="col-lg-4 ml-auto text-center mb-5 mb-lg-0">
                <i className="fas fa-envelope fa-3x mb-3 text-muted"></i>
                <a
                  className="d-block text-white"
                  href="mailto:info@nozzolillo.com"
                >
                  info@nozzolillo.com
                </a>
              </div>
              <div className="col-lg-4 ml-auto text-center mb-5 mb-lg-0">
                <i className="fas fa-building fa-3x mb-3 text-muted"></i>
                <div>2163 Merrick Avenue</div>
                <div>2nd Floor</div>
                <div>Merrick, NY 11566</div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </Layout>
    )
  }
}

export const imageData = graphql`
  query {
    images: allFile(
      filter: { relativePath: { glob: "portfolio/fullsize/*.jpg" } }
      sort: { fields: name }
    ) {
      edges {
        node {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
